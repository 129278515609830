@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  composes: marketplaceModalRootStyles from global;
  composes: defaultBackgroundImageForDesktop from global;
  position: relative;
}

.content {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 30px;
  width: 360px;
  @media (--viewportMedium) {
    width: 415px;
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  border-bottom: 1px solid var(--tabNavHorizontalColor);
  justify-content: center;
  margin-top: 6px;
  font-size: 14px;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
  @media (--viewportLarge) {
    justify-content: center;
  }
}

.tab {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */

  flex-basis: 330px;
  margin-top: 24px;
}

.signupForm {
  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */

  margin-top: 24px;
  flex-basis: 380px;

  @media (--viewportMedium) {
    flex-basis: 410px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 48px 0;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  /* text-align: left; */
}

/* Terms of Service modal*/

.termsModalContainer {
  width: 100vw;

  @media (--viewportMedium) {
    width: 500px;
  }
}

.termsWrapper {
  width: 100%;

  & > div {
    background-color: #fff!important;

    & > div {
      padding: 32px 0;
    }
  }
}

/* ================ Close icon ================ */

.verifyClose {
  composes: marketplaceModalCloseStyles from global;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
  border-bottom: 1px solid #ebebeb;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.linkButtonBase {
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadius);
  color: var(--marketplaceColorDark);
  font-weight: 700;
  font-size: 14px;
  &:hover {
    text-decoration: none;
  }
}

.resendEmail {
  composes: linkButtonBase;
  background-color: var(--marketplaceColor);
  &:hover {
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
    color: var(--marketplaceColor);
  }
}

.changeEmail {
  composes: linkButtonBase;
  background-color: var(--matterColorLight);
  &:hover {
    background-color: var(--marketplaceColor);
  }
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  composes: marketplaceModalPasswordMargins from global;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  composes: marketplaceModalTitleStyles from global;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.confirmInfoText {
  composes: h4 from global;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }

  @media (--viewportMedium) {
    .socialButtonsOr {
      height: 34px;
      margin: 15px 0;
    }
  }
}

.socialButtonsOrText {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.logo {
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-top: -66px;
}


